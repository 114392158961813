
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import Swal from "sweetalert2/dist/sweetalert2.js";
import _ from "lodash";
import {
  CommonHtmlType,
  CommonSwtich,
  NumberOrString,
} from "@/core/directive/type/common";
import ElUploadList from "@/components/upload/ElUploadList.vue";
import { ApiBase, ApiOrderRequest } from "@/core/api";
import {
  commonChangeDefaultDate,
  formatDate,
  modalShowListener,
} from "@/core/directive/function/common";
import { getMerchantOrderInvoiceStatus } from "@/core/directive/function/orderRequest";
import {
  getCurrencyTypeOptions,
  getOrderItemAmountTotal,
  getOrderItemVatTotal,
} from "@/core/directive/function/order";
import { CurrencyType } from "@/core/directive/type/order";
import ProductInfo from "@/components/layout/ProductInfo.vue";
import {
  orderRequestInvoiceData,
  OrderRequestInvoiceRelationInvoiceItem,
} from "@/core/directive/interface/orderRequest";
import { getVatTypeOptions } from "@/core/directive/function/merchantInvoice";
import AddProduct from "./addProduct.vue";
import SkuInfo from "@/components/layout/SkuInfo.vue";
import UpdateShippingFee from "./UpdateShippingFee.vue";
import { InvoiceStatus } from "@/core/directive/type/orderRequest";
import { MerchantOption } from "@/core/directive/interface/common";
import md5 from "js-md5";
import accounting from "accounting-js";

export default defineComponent({
  name: "order-request-update-invoice-modal",
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  components: {
    ElUploadList,
    ProductInfo,
    AddProduct,
    SkuInfo,
    UpdateShippingFee,
  },
  emits: ["update-list", "reset-form"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const route = useRoute();
    const loading = ref<boolean>(false);
    const uploading = ref<boolean>(false);
    const submitButtonRef = ref<Array<HTMLElement | null>>([]);

    const submitButton = (el) => {
      submitButtonRef.value.push(el);
    };

    const setSubmitAttribute = (flag = true) => {
      if (!submitButtonRef.value) return;
      submitButtonRef.value.map((el) => {
        if (flag) {
          el?.setAttribute("data-kt-indicator", "on");
        } else {
          el?.removeAttribute("data-kt-indicator");
        }
      });
    };

    const updateInvoiceRef = ref<CommonHtmlType>(null);
    const elUploadListRef = ref<null | HTMLFormElement>(null);
    const formRef = ref<null | HTMLFormElement>(null);
    const fileList = ref<any[]>([]);
    const uploadUrl = ApiOrderRequest.upload();
    const uploadData = computed(() => {
      return {
        merchant_invoice_id: props.id,
      };
    });

    const formData = ref(Object.assign({}, orderRequestInvoiceData));
    const oldData = ref("");

    const rules = ref({
      invoice_date: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      invoice_number: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      merchant: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      invoice_file: [
        {
          required: true,
          asyncValidator: (rule, value) => {
            return new Promise<void>((resolve, reject) => {
              if (value === 0) {
                reject(t("common.isRequired"));
              } else {
                resolve();
              }
            });
          },
          trigger: "change",
        },
      ],
    });

    const options = ref({
      discount: getCurrencyTypeOptions(),
      isTaxCount: true,
      merchantLoading: false,
      merchantOptions: [] as MerchantOption[],
      merchantMap: new Map<NumberOrString, MerchantOption>([]),
      list: [] as any[],
      vatTypeOptions: getVatTypeOptions(t),
    });

    const getSalesChannelsData = (id: NumberOrString) => {
      formData.value.channel = "";
      formData.value.relation_invoice_item = [];
      const option: MerchantOption | undefined =
        options.value.merchantMap.get(id);
      if (option) {
        formData.value.__show.taxRates = option.entity_data.__show.tax_rates;
      }
    };

    const getMerchantSourceData = async (value, id?) => {
      options.value.merchantLoading = true;
      let params = id
        ? {
            id: id,
          }
        : {
            search_value: value,
          };
      options.value.merchantLoading = false;
      const { data } = await ApiBase.getMerchantSourceData({
        ...params,
        options: {
          include_fields: ["tax_rates"],
        },
      });
      if (data.code == 0) {
        options.value.merchantOptions = data.data;
        data.data.forEach((item) => {
          options.value.merchantMap.set(item.value, item);
        });
      }
    };

    const debounceMerchantSourceSearch = _.debounce(
      getMerchantSourceData,
      1000
    );

    const searchMerchantSourceItems = (query: string) => {
      debounceMerchantSourceSearch(query);
    };

    const fileChange = (data) => {
      formData.value.invoice_file = data.data.file_id;
      emit("update-list");
    };

    const removeFile = (item) => {
      formData.value.invoice_file = 0;
    };

    const removeFiles = () => {
      formData.value.invoice_file = 0;
    };

    const uploadStatusChange = (value: boolean) => {
      uploading.value = value;
    };

    const updateShippingFee = (e) => {
      formData.value.shipping_charges = e.amount;
      formData.value.shipping_tax_rate = e.tax || 0;
    };

    const updateProduct = (
      products: OrderRequestInvoiceRelationInvoiceItem[]
    ) => {
      let arr: OrderRequestInvoiceRelationInvoiceItem[] = products.filter(
        (item) =>
          getSubmitSkuIds.value.indexOf(
            item.sku_identifier + "_" + item.request_number
          ) === -1
      );
      if (arr.length > 0) {
        // 回显渠道
        const oldArr = formData.value.channel
          .split(",")
          .map((item) => item.trim())
          .filter((item) => item !== "");
        const addArr = arr.map(
          (item) => `${item.__show.channel_name} ${item.__show.channel_type}`
        );
        const combinedArr = Array.from(new Set([...oldArr, ...addArr]));
        formData.value.channel = combinedArr.join(", ");
      }
      formData.value.relation_invoice_item.push(...arr);
    };

    const taxChange = (item, index) => {
      item.list.forEach((row) => {
        formData.value.relation_invoice_item[row.index].tax_rate =
          item.tax_rate;
        priceChange(
          formData.value.relation_invoice_item[row.index].unit_gross_amount,
          "unit_gross_amount",
          row.index
        );
      });
    };

    const priceChange = (e: string | number, type: string, index: number) => {
      const price = Number(e),
        tax_rate = formData.value.relation_invoice_item[index].tax_rate;
      if (type === "unit_net_amount") {
        formData.value.relation_invoice_item[index].unit_gross_amount =
          price * (1 + tax_rate / 100);
      } else {
        formData.value.relation_invoice_item[index].unit_net_amount =
          price / (1 + tax_rate / 100);
      }
    };

    const getSameProduct = computed(() => {
      let arr: any[] = [],
        strPuid: string[] = [];
      formData.value.relation_invoice_item.map((item, index) => {
        let findIndex = -1;
        if (item.product_identifier_id) {
          findIndex = strPuid.indexOf(
            item.product_identifier_id + item.tax_rate
          );
        }
        const listItem: any = Object.assign({}, item, {
          index: index,
        });
        const amounts =
          typeof item.confirmed_qty === "number" ? item.confirmed_qty : 0;
        const count = getOrderItemAmountTotal.value(
          item.confirmed_qty,
          getNetPrice.value(item),
          item.discount,
          item.discount_type,
          item.tax_rate,
          isGrossCalculation.value
        );
        if (findIndex === -1) {
          arr.push({
            ...item,
            total_qty: amounts,
            total: count,
            isExpand: false,
            list: [listItem],
          });
          if (item.product_identifier_id) {
            strPuid.push(item.product_identifier_id + item.tax_rate);
          }
        } else {
          arr[findIndex].total_qty += amounts;
          arr[findIndex].total += count;
          arr[findIndex].list.push(listItem);
        }
      });
      return arr;
    });

    const keepItemExpanded = (newList: any[], oldList: any[]) => {
      if (Array.isArray(newList) && Array.isArray(oldList)) {
        const temp = [...newList];
        oldList.forEach((item) => {
          const index = temp.findIndex((i) => i.id === item.id);
          if (index > -1) {
            temp[index].isExpand = item.isExpand;
          }
        });
        return temp;
      } else {
        return [];
      }
    };

    watch(
      () => getSameProduct.value,
      (newValue, oldValue) => {
        options.value.list = keepItemExpanded(newValue, oldValue);
      }
    );

    const isChangeData = computed(() => {
      const newData = md5(JSON.stringify(formData.value));
      const flag = newData !== oldData.value;
      return flag;
    });

    const isSameTotalAmount = computed(() => {
      const total = Number(formData.value.total_amount);
      const totalAmount = Number(getOrderItemsTotal.value.toFixed(2));
      let flag = false;
      const diff = accounting.toFixed(total - totalAmount, 2);
      if (Math.abs(diff) <= 0) {
        flag = true;
      }
      return flag;
    });

    const showCheckTip = () => {
      Swal.fire({
        text: t("merchantCreditNotes.checkTip"),
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: t("common.okGotIt"),
        customClass: {
          confirmButton: "btn btn-primary",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          //
        }
      });
    };

    const removeItems = () => {
      formData.value.relation_invoice_item = [];
    };

    const expandSub = (item) => {
      item.isExpand = !item.isExpand;
    };

    const removeItem = (index: number) => {
      // 回显渠道
      const item = formData.value.relation_invoice_item[index];
      const oldArr = formData.value.channel
        .split(",")
        .map((item) => item.trim())
        .filter((item) => item !== "");
      const removeArr = [
        `${item.__show.channel_name} ${item.__show.channel_type}`,
      ];
      const combinedArr = oldArr.filter((item) => !removeArr.includes(item));
      formData.value.channel = combinedArr.join(", ");

      // 删除数据
      formData.value.relation_invoice_item.splice(index, 1);
    };

    const isGrossCalculation = computed(() => {
      const value = formData.value.price_calc_mode !== "net";
      if (value) {
        return true;
      }
      return false;
    });

    const getOPrice = computed(() => {
      return (item) => {
        const price = options.value.isTaxCount
          ? Number(item.unit_gross_amount) / (1 + item.tax_rate / 100)
          : Number(item.unit_net_amount);
        return price;
      };
    });

    const getNetPrice = computed(() => {
      return (item) => {
        const price = isGrossCalculation.value
          ? Number(item.unit_gross_amount)
          : Number(item.unit_net_amount);
        return Number(price.toFixed(2));
      };
    });

    const getAmountSubTotal = computed(() => {
      let count = 0;
      formData.value.relation_invoice_item.map((item) => {
        count += getOrderItemAmountTotal.value(
          item.confirmed_qty,
          getNetPrice.value(item),
          item.discount,
          item.discount_type,
          item.tax_rate,
          isGrossCalculation.value
        );
      });
      return count;
    });

    const getAmountVatSubTotal = computed(() => {
      let count = 0;
      formData.value.relation_invoice_item.map((item) => {
        const price = getOrderItemAmountTotal.value(
          item.confirmed_qty,
          getNetPrice.value(item),
          item.discount,
          item.discount_type,
          item.tax_rate,
          isGrossCalculation.value
        );
        count += Number(accounting.toFixed(price, 2));
      });
      return Number(accounting.toFixed(count, 2));
    });

    const diffSubTotalOne = computed(() => {
      return Number(
        accounting.toFixed(
          getAmountSubTotal.value - getAmountVatSubTotal.value,
          2
        )
      );
    });

    const diffSubTotalTwo = computed(() => {
      return Number(
        accounting.toFixed(
          getOrderItemsVatTotal.value - getOrderItemsTotal.value,
          2
        )
      );
    });

    const diffSubTotal = computed(() => {
      // const diff_one = diffSubTotalOne.value;
      // const diff_two = diffSubTotalTwo.value;
      return diffSubTotalOne.value;
    });

    const getVatArr = computed(() => {
      let vatArr: any[] = [],
        vatPercent: number[] = [];
      formData.value.relation_invoice_item.map((item) => {
        const price = getNetPrice.value(item);
        let amounts = (Number(item.confirmed_qty) || 0) * Number(price);
        if (vatPercent.indexOf(item.tax_rate) > -1) {
          vatArr[vatPercent.indexOf(item.tax_rate)].base_tax_amount += Number(
            accounting.toFixed(amounts, 2)
          );
        } else {
          vatPercent.push(item.tax_rate);
          vatArr.push({
            tax_percent: item.tax_rate,
            base_tax_amount: amounts,
          });
        }
      });
      return vatArr;
    });

    const getVatItemTotal = computed(() => {
      return (tax: number) => {
        let count = 0;
        formData.value.relation_invoice_item.map((item) => {
          if (item.tax_rate === tax) {
            count += getOrderItemVatTotal.value(
              item.confirmed_qty,
              getNetPrice.value(item),
              item.discount,
              item.discount_type,
              item.tax_rate,
              isGrossCalculation.value
            );
          }
        });
        return Number(accounting.toFixed(count, 2));
      };
    });

    const getVatTotal = computed(() => {
      let count = 0;
      formData.value.relation_invoice_item.map((item) => {
        count += getOrderItemVatTotal.value(
          item.confirmed_qty,
          getNetPrice.value(item),
          item.discount,
          item.discount_type,
          item.tax_rate,
          isGrossCalculation.value
        );
      });
      return count;
    });

    const getVatTotalT = computed(() => {
      let count = 0;
      formData.value.relation_invoice_item.map((item) => {
        const price = getOrderItemVatTotal.value(
          item.confirmed_qty,
          getNetPrice.value(item),
          item.discount,
          item.discount_type,
          item.tax_rate,
          isGrossCalculation.value
        );
        count += Number(accounting.toFixed(price, 2));
      });
      return Number(accounting.toFixed(count, 2));
    });

    const diffVatTotal = computed(() => {
      return Number(
        accounting.toFixed(getVatTotalT.value - getVatTotal.value, 2)
      );
    });

    const getShippingChargesTotal = computed(() => {
      return (
        getOrderItemAmountTotal.value(
          1,
          Number(formData.value.shipping_charges),
          0,
          CurrencyType.Amount,
          formData.value.shipping_tax_rate,
          false
        ) +
        getOrderItemVatTotal.value(
          1,
          Number(formData.value.shipping_charges),
          0,
          CurrencyType.Amount,
          formData.value.shipping_tax_rate,
          false
        )
      );
    });

    const getOrderItemsTotal = computed(() => {
      let count = 0;
      count += Number(accounting.toFixed(getAmountSubTotal.value, 2));
      count += Number(accounting.toFixed(getVatTotal.value, 2));
      count += Number(accounting.toFixed(getShippingChargesTotal.value, 2));
      return count;
    });

    const getOrderItemsVatTotal = computed(() => {
      let count = 0;
      count += Number(accounting.toFixed(getAmountVatSubTotal.value, 2));
      count += Number(accounting.toFixed(getVatTotalT.value, 2));
      count += Number(accounting.toFixed(getShippingChargesTotal.value, 2));
      count += getShippingChargesTotal.value;
      return Number(accounting.toFixed(count, 2));
    });

    const getSubmitSkuIds = computed(() => {
      let ids: string[] = [];
      formData.value.relation_invoice_item.map((item) => {
        ids.push(item.sku_identifier + "_" + item.request_number);
      });
      return ids;
    });

    const getSubmitotal = computed(() => {
      let arr: any[] = [];
      formData.value.relation_invoice_item.map((item, index) => {
        if (item.confirmed_qty >= 0 && typeof item.confirmed_qty === "number") {
          const price = getNetPrice.value(item);
          const total_net_amount = getOrderItemAmountTotal.value(
            item.confirmed_qty,
            price,
            item.discount,
            item.discount_type,
            item.tax_rate,
            isGrossCalculation.value
          );
          const total_gross_amount =
            getOrderItemAmountTotal.value(
              item.confirmed_qty,
              price,
              item.discount,
              item.discount_type,
              item.tax_rate,
              isGrossCalculation.value
            ) +
            getOrderItemVatTotal.value(
              item.confirmed_qty,
              price,
              item.discount,
              item.discount_type,
              item.tax_rate,
              isGrossCalculation.value
            );
          arr.push({
            sku_identifier: item.sku_identifier,
            product_identifier_id: item.product_identifier_id,
            request_items_id: item.request_items_id,
            items_id: item.id ? item.id : 0,
            qty: item.confirmed_qty,
            request_number: item.request_number,
            currency: item.currency,
            unit_net_amount: item.unit_net_amount,
            unit_gross_amount: item.unit_gross_amount,
            discount_type: item.discount_type,
            discount: item.discount,
            tax_rate: item.tax_rate,
            total_net_amount: Number(
              total_net_amount +
                (index === formData.value.relation_invoice_item.length - 1
                  ? diffSubTotal.value
                  : 0)
            ).toFixed(2),
            total_gross_amount: Number(total_gross_amount).toFixed(2),
          });
        }
      });
      return arr;
    });

    const changeTax = () => {
      options.value.isTaxCount = !options.value.isTaxCount;
    };

    const isEdit = computed(() => {
      let flag = false;
      if (
        formData.value.status === InvoiceStatus.Pending ||
        formData.value.status === InvoiceStatus.Checked ||
        formData.value.status === InvoiceStatus.Paid ||
        formData.value.status === InvoiceStatus.Unpaid
      ) {
        flag = true;
      }
      return flag;
    });

    const getShowInfo = async () => {
      if (props.id === 0) return false;
      loading.value = true;
      const { data } = await ApiOrderRequest.getInfo({
        request_invoice_id: props.id,
      });
      loading.value = false;
      if (data.code == 0) {
        data.data.relation_invoice_item.forEach((item, index) => {
          item.confirmed_qty = item.qty;
        });
        formData.value = data.data;
        if (formData.value.invoice_file !== 0) {
          // fileList.value = [
          //   {
          //     id: formData.value.invoice_file,
          //     name: data.data.__show.invoice_file,
          //     size: data.data.__show.file_size,
          //   },
          // ];
          elUploadListRef.value?.init([
            {
              id: formData.value.invoice_file,
              name: data.data.__show.invoice_file,
              size: data.data.__show.file_size,
            },
          ]);
        }
        if (data.data.merchant) {
          getSourceInfo(data.data.merchant);
        }
      } else {
        showServerErrorMsg(data);
      }
    };

    const getSourceInfo = (id: NumberOrString) => {
      loading.value = true;
      Promise.all([getMerchantSourceData("", id)])
        .then(() => {
          loading.value = false;
          oldData.value = md5(JSON.stringify(formData.value));
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getFormInfo = () => {
      options.value.merchantOptions = [];
      options.value.merchantMap.clear();
      loading.value = true;
      Promise.all([getShowInfo()])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const verify = async () => {
      // if (!isSameTotalAmount.value) {
      //   Swal.fire({
      //     text: t("orderRequest.verifiedAmountTip"),
      //     icon: "error",
      //     buttonsStyling: false,
      //     confirmButtonText: t("common.okGotIt"),
      //     customClass: {
      //       confirmButton: "btn btn-primary",
      //     },
      //   });
      //   return false;
      // }
      loading.value = true;
      const { data } = await ApiOrderRequest.verified({
        request_invoice_id: props.id,
      });
      loading.value = false;
      if (data.code == 0) {
        // hideModal(updateInvoiceRef.value);
        emit("update-list");
        resetData();
        getFormInfo();
      } else {
        showServerErrorMsg(data);
      }
    };

    // const confirmPayment = () => {
    //   Swal.fire({
    //     text: t("orderRequest.confirmPaymentTip"),
    //     icon: "warning",
    //     showCancelButton: true,
    //     buttonsStyling: false,
    //     confirmButtonText: t("common.okConfirm"),
    //     cancelButtonText: t("common.nopeCancelIt"),
    //     customClass: {
    //       confirmButton: "btn btn-primary",
    //       cancelButton: "btn btn-danger",
    //     },
    //   }).then(async (result) => {
    //     if (result.isConfirmed) {
    //       loading.value = true;
    //       const { data } = await ApiOrderRequest.confirmPayment({
    //         request_invoice_id: props.id,
    //       });
    //       loading.value = false;
    //       if (data.code == 0) {
    //         // hideModal(updateInvoiceRef.value);
    //         emit("update-list");
    //         resetData();
    //         getFormInfo();
    //       } else {
    //         showServerErrorMsg(data);
    //       }
    //     }
    //   });
    // };

    const paidTip = (callback?) => {
      Swal.fire({
        text: t("merchantCreditNotes.paidSaveTip"),
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: t("common.confirm"),
        cancelButtonText: t("common.cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          callback && callback();
        }
      });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate(async (valid: boolean) => {
        if (valid) {
          if (loading.value) return;

          if (formData.value.status === InvoiceStatus.Checked) {
            checkedInvoice();
          } else {
            submitValidate(async (params) => {
              if (formData.value.status === InvoiceStatus.Paid) {
                paidTip(() => {
                  updateInvoice(params);
                });
              } else {
                updateInvoice(params);
              }
            });
          }
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const submitValidate = (callback) => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate(async (valid: boolean) => {
        if (valid) {
          if (loading.value) return;
          let accounting_month = formData.value.accounting_month;
          // if (moment(accounting_month).isValid()) {
          //   if (!moment(accounting_month).isUTC()) {
          //     accounting_month = commonDefaultMonth(
          //       formData.value.accounting_month
          //     );
          //   }
          // }
          const params = {
            // merchant_request_id: formData.value.merchant_request_id,
            request_invoice_id: props.id,
            invoice_number: formData.value.invoice_number,
            invoice_date: formData.value.invoice_date,
            merchant: formData.value.merchant,
            channel: formData.value.channel,
            vat_refundable: formData.value.vat_refundable,
            overall_notes: formData.value.overall_notes,
            currency: "EUR",
            total_amount: formData.value.total_amount,
            external_order_number: formData.value.external_order_number,
            shipping_charges: formData.value.shipping_charges,
            shipping_tax_rate: formData.value.shipping_tax_rate,
            invoice_file: formData.value.invoice_file,
            accounting_month: accounting_month,
            payment_date: formData.value.payment_date,
            items: getSubmitotal.value,
            price_calc_mode: formData.value.price_calc_mode,
          };
          callback(params);
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const checkedInvoice = () => {
      submitValidate(async (params) => {
        if (!isSameTotalAmount.value) {
          showCheckTip();
          return;
        }
        loading.value = true;
        setSubmitAttribute();
        const { data } = await ApiOrderRequest.checked(params);
        loading.value = false;
        setSubmitAttribute(false);
        if (data.code == 0) {
          showFormSubmitSuccessMsg(() => {
            // hideModal(updateInvoiceRef.value);
            emit("update-list");
            resetData();
            getFormInfo();
          });
        } else {
          showServerErrorMsg(data);
        }
      });
    };

    const updateInvoice = async (params) => {
      loading.value = true;
      setSubmitAttribute();
      const { data } = await ApiOrderRequest.save(params);
      loading.value = false;
      setSubmitAttribute(false);
      if (data.code == 0) {
        showFormSubmitSuccessMsg(() => {
          // hideModal(updateInvoiceRef.value);
          emit("update-list");
          resetData();
          getFormInfo();
        });
      } else {
        showServerErrorMsg(data);
      }
    };

    const handleDiscard = () => {
      hideModal(updateInvoiceRef.value);
    };

    const resetData = () => {
      formRef.value?.resetFields();
      fileList.value = [];
      uploading.value = false;
    };

    const resetForm = () => {
      resetData();
      emit("reset-form");
    };

    const init = () => {
      setTimeout(() => {
        getFormInfo();
      }, 0);
    };

    onMounted(() => {
      modalShowListener(updateInvoiceRef.value, () => {
        init();
      });
      modalHideListener(updateInvoiceRef.value, () => {
        resetForm();
      });
    });

    return {
      t,
      CommonSwtich,
      formatDate,
      commonChangeDefaultDate,
      InvoiceStatus,
      CurrencyType,
      formRef,
      submitButtonRef,
      submitButton,
      updateInvoiceRef,
      props,
      loading,
      uploading,
      formData,
      fileList,
      uploadUrl,
      uploadData,
      rules,
      options,
      elUploadListRef,
      searchMerchantSourceItems,
      getSalesChannelsData,
      fileChange,
      removeFile,
      removeFiles,
      taxChange,
      priceChange,
      uploadStatusChange,
      updateShippingFee,
      updateProduct,
      isChangeData,
      isSameTotalAmount,
      removeItems,
      expandSub,
      removeItem,
      isGrossCalculation,
      getOPrice,
      getNetPrice,
      getOrderItemAmountTotal,
      getOrderItemVatTotal,
      getAmountSubTotal,
      getAmountVatSubTotal,
      diffSubTotalOne,
      diffSubTotalTwo,
      diffSubTotal,
      getVatArr,
      getVatItemTotal,
      getVatTotal,
      getVatTotalT,
      diffVatTotal,
      getOrderItemsTotal,
      getOrderItemsVatTotal,
      changeTax,
      isEdit,
      handleDiscard,
      resetForm,
      verify,
      // confirmPayment,
      checkedInvoice,
      updateInvoice,
      submit,
      getMerchantOrderInvoiceStatus,
    };
  },
});
