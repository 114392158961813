
import { computed, defineComponent, onActivated, onMounted, ref } from "vue";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import { CommonFormType, CommonHtmlType } from "@/core/directive/type/common";
import { ApiOrderRequest } from "@/core/api";
import _ from "lodash";
import {
  OrderRequestInvoiceRelationInvoiceItem,
  OrderRequestInvoiceRelationInvoiceProductTable,
} from "@/core/directive/interface/orderRequest";
import SkuInfo from "@/components/layout/SkuInfo.vue";
import {
  formatDate,
  modalShowListener,
} from "@/core/directive/function/common";
import ProductInfo from "@/components/layout/ProductInfo.vue";
import { ScrollComponent } from "@/assets/ts/components";

export default defineComponent({
  components: { SkuInfo, ProductInfo },
  name: "settlement-merchant-invoice-add-product",
  emits: ["update-list", "reset-form"],
  props: {
    merchantId: {
      type: Number,
      default: 0,
    },
    merchantMap: {
      type: Map,
      default: new Map([]),
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();

    const loading = ref<boolean>(false);
    const formRef = ref<CommonFormType>(null);
    const merchantOrderList = ref<CommonHtmlType>(null);
    const submitButton = ref<CommonHtmlType>(null);

    const { showValidateErrorMsg } = mixin();

    const formData = ref({
      search_value: "",
      ids: [] as string[],
      skuList: [] as OrderRequestInvoiceRelationInvoiceItem[],
      items: [] as OrderRequestInvoiceRelationInvoiceProductTable[],
    });

    const rules = ref({});

    const options = ref({});

    const toggleItemSelect = (
      item: OrderRequestInvoiceRelationInvoiceProductTable
    ) => {
      item.isChecked = !item.isChecked;
      let isChecked = item.isChecked;
      item.items.forEach((row) => {
        row.isChecked = isChecked === true ? false : true;
        toggleSubSelect(item, row);
      });
    };

    const toggleSubSelect = (
      item: OrderRequestInvoiceRelationInvoiceProductTable,
      sub: OrderRequestInvoiceRelationInvoiceItem
    ) => {
      sub.isChecked = !sub.isChecked;
      const str: string = sub.sku_identifier + "_" + item.request_number;
      if (sub.isChecked) {
        if (formData.value.ids.indexOf(str) === -1) {
          formData.value.ids.push(str);
          formData.value.skuList.push(sub);
        }
      } else {
        const findIndex = formData.value.ids.indexOf(str);
        if (findIndex > -1) {
          formData.value.ids.splice(findIndex, 1);
          formData.value.skuList.splice(findIndex, 1);
        }
      }
      const checkIndex = item.items.findIndex((sub) => !sub.isChecked);
      item.isChecked = checkIndex === -1;
    };

    const expandSub = (
      item: OrderRequestInvoiceRelationInvoiceProductTable
    ) => {
      item.isExpand = !item.isExpand;
    };

    const isDisabledSubmit = computed(() => {
      let isFlag = false;
      if (formData.value.skuList.length === 0) {
        isFlag = true;
      }
      return isFlag;
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate(async (valid) => {
        const data = formData.value.skuList;
        if (valid) {
          emit("update-list", data);
          hideModal(merchantOrderList.value);
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const getShowInfo = async () => {
      const { data } = await ApiOrderRequest.searchItem({
        request_number: formData.value.search_value,
        merchant_id: props.merchantId,
      });
      if (data.code == 0) {
        const ids = formData.value.ids;
        let items: OrderRequestInvoiceRelationInvoiceProductTable[] = data.data;
        items.forEach(
          (item: OrderRequestInvoiceRelationInvoiceProductTable) => {
            let checkedNum = 0;
            item.items.forEach(
              (row: OrderRequestInvoiceRelationInvoiceItem) => {
                row.request_number = item.request_number;
                row.sku_identifier = row.sku_id;
                row.request_date = item.request_date;
                row.confirmed_qty = row.qty;
                row.product_identifier_id = row.__show.product_identifier_id;
                if (
                  ids.indexOf(row.sku_identifier + "_" + item.request_number) >
                  -1
                ) {
                  row.isChecked = true;
                  checkedNum++;
                } else {
                  row.isChecked = false;
                }
              }
            );
            if (item.items.length === checkedNum) {
              item.isChecked = true;
            } else {
              item.isChecked = false;
            }
            item.isExpand = true;
          }
        );
        formData.value.items = items;
      }
    };

    const getFormInfo = () => {
      loading.value = true;
      Promise.all([getShowInfo()])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const resetForm = () => {
      formRef.value?.resetFields();
      formData.value.search_value = "";
    };

    onActivated(() => {
      ScrollComponent.reinitialization();
    });

    onMounted(() => {
      ScrollComponent.reinitialization();
      modalShowListener(merchantOrderList.value, () => {
        getFormInfo();
      });
      modalHideListener(merchantOrderList.value, () => {
        resetForm();
      });
    });

    return {
      t,
      formatDate,
      props,
      merchantOrderList,
      loading,
      formData,
      submitButton,
      formRef,
      options,
      rules,
      toggleItemSelect,
      toggleSubSelect,
      expandSub,
      isDisabledSubmit,
      submit,
      resetForm,
      getShowInfo,
      getFormInfo,
    };
  },
});
